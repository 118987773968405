/*.mainpaneloutlet {
  &.dark {
    background-color: #160a38;
  }

  &.light {
    background-color: #ffffff;
  }
}*/

* {
  padding: 0;
  margin: 0;
}
html,
body {
  min-height: 100% !important;
  height: 100%;
}

.menu-item {
  filter: drop-shadow(2px 2px 2px rgb(64, 63, 63));
}

.homepagediv {
  width: 100%;
  padding: 4vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 3vh;
  border-radius: 5vh;

  &.dark {
    background-color: rgba(22, 10, 56, 0.5);
  }

  &.light {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.hititpagediv {
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-radius: 5vh;
}

.commongamediv {
  width: 100%;
  padding: 4vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 3vh;
}

.gamespagediv {
  width: 100%;
  overflow-y: auto;
  display: flex;
}

.gameslistdiv {
  width: 100%;
  padding: 4vh;
  overflow-y: auto;
  display: flex;
  gap: 3vh;
  flex-wrap: wrap;
  height: fit-content;

  @media (max-width: 890px) {
    align-items: center;
    justify-content: center;
  }
}

.gamelinkbutton {
  width: 5vh;
  height: 5vh;
  min-width: 250px;
  min-height: 250px;
  filter: drop-shadow(2px 2px 2px rgb(64, 63, 63));
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 4vh;
  flex-grow: 1;
  flex-basis: 20%;

  img {
    width: 4vh;
    height: 4vh;
    min-width: 200px;
    min-height: 200px;
    filter: drop-shadow(2px 2px 2px rgb(64, 63, 63));
  }

  .gamelinktitle {
    font-size: 2vh;
    color: rgb(245, 231, 234);
  }
}

.mainhomecard {
  width: 100%;
  border-radius: 2vh;
  filter: drop-shadow(2px 2px 2px rgb(64, 63, 63));
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 890px) {
    flex-direction: column;
    gap: 3vh;
    padding-top: 3vh;
  }
}

.homecardplayeravatarbox {
  display: flex;
  align-items: flex-end;
  height: 100%;

  @media (min-width: 890px) {
    order: 2;
  }

  @media (max-width: 890px) {
    order: 3;
  }
}

.homecardplayeravatar {
  width: 14wh;
  height: 28vh;
}

.homefavoritescard {
  @media (min-width: 890px) {
    order: 1;
  }

  @media (max-width: 890px) {
    order: 2;
  }
}

.homescorecard {
  @media (min-width: 890px) {
    order: 3;
  }

  @media (max-width: 890px) {
    order: 1;
  }
}

.homepageoptionsdiv {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2vh;
}

.homepageoption {
  height: 20vh;
  min-height: 180px;
  flex-grow: 1;
  flex-basis: 25%;
  border-radius: 2vh;
  filter: drop-shadow(2px 2px 2px rgb(64, 63, 63));
  background-color: #19a2ab;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  padding: 2vh;

  img {
    height: 18vh;
    min-height: 160px;
    filter: drop-shadow(2px 2px 2px rgb(64, 63, 63));
  }

  &.art {
    background: rgb(195, 101, 34);
    background: linear-gradient(
      0deg,
      rgba(195, 101, 34, 1) 0%,
      rgba(249, 253, 45, 1) 100%
    );
  }

  &.racing {
    background: rgb(34, 195, 190);
    background: linear-gradient(
      0deg,
      rgba(34, 195, 190, 1) 0%,
      rgba(45, 74, 253, 1) 100%
    );
  }

  &.puzzle {
    background: rgb(245, 231, 234);
    background: linear-gradient(
      0deg,
      rgba(245, 231, 234, 1) 0%,
      rgba(222, 25, 19, 1) 100%
    );
  }

  &.stickers {
    background: rgb(245, 231, 234);
    background: linear-gradient(
      0deg,
      rgba(245, 231, 234, 1) 0%,
      rgba(78, 222, 19, 1) 100%
    );
  }
}

.homecard {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 3vh;
  height: 20vh;
  min-height: 205px;
  width: 20vh;
  min-width: 205px;
  margin-bottom: 2vh;
  filter: drop-shadow(2px 2px 2px rgb(64, 63, 63));
  border-radius: 2vh;

  > .container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }

  &.dark {
    background: #004e74;
    background: -webkit-linear-gradient(177deg, #004e74 0%, #021329 100%);
    background: linear-gradient(177deg, #004e74 0%, #021329 100%);
  }

  &.light {
    background: #86eebd;
    background: -webkit-linear-gradient(177deg, #86eebd 0%, #19a2ab 100%);
    background: linear-gradient(177deg, #86eebd 0%, #19a2ab 100%);
  }

  & img {
    height: 5vh;
    filter: drop-shadow(2px 2px 2px rgb(64, 63, 63));
  }
}

.commontext {
  &.dark {
    color: #86eebd;
  }

  &.light {
    color: #160a38;
  }

  &.heb {
    font-family: 'Rubik';
    font-weight: 900;
  }
}

.inventorytext {
  font-size: 3vh;
}

.gameboard-common-button {
  width: min-content;
  transition: background-color 0.2s linear;
  padding: 0px 8px;

  &.hover {
    background-color: #86eebd;
    cursor: pointer;
  }
}
